//  verticalMovement from right to center to bottom move
.myDownMoveMobile{
  display: block !important;
  position: relative;
  top: 450px;
  right: -150px;
  -webkit-animation: myDownMoveMobile 4s forwards ease-out;
}
@-webkit-keyframes myDownMoveMobile {
  to {
    -webkit-transform: translateY(500px);
    -moz-transform: translateY(500px);
    -ms-transform: translateY(500px);
    -o-transform: translateY(500px);
    transform: translateY(200px) scale(1.5) translateX(-100px)  translateY(50px);
  }
}
// left to center into frame
.leftToFrameMobile{
  display: block !important;
  position: relative;
  left: -230px;
  -webkit-animation: leftToFrameMobile 1s forwards ease-out;
}
@-webkit-keyframes leftToFrameMobile {
  to {
    -webkit-transform: translateY(250px);
    -moz-transform: translateY(250px);
    -ms-transform: translateY(250px);
    -o-transform: translateY(250px);
    transform:  translateX(250px) scale(1.5)  ;
  }
}
//right to frame
.yellow_circle_moving_right{
  display: none;
  position: relative;
  left: 110%;
  top: -10rem;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #FAB514;
  overflow: hidden;
}
//right to frame
.Orangecircle_moving_right{
  display: none;
  position: relative;
  left: 110%;
  top: 5rem;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #FF6004;
  overflow: hidden;
}
.Orangecircle_moving_right_small{
  display: none;
  position: relative;
  left: 110%;
  bottom: 250px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FF6004;
  overflow: hidden;
}
.yellow_circle_moving_right_animations{
  display: inline-block;
  animation: moveRight 2s ease-out forwards;
}
@keyframes moveRight {

  to {
    left: 80%;
    scale: 2;
  }
}
@media screen and (min-width: 1022px) {
  .Orangecircle_moving_right_small{
    bottom: 75px;
  }
}
