/* You can add global styles to this file, and also import other style files */
@import "./app/styles/font";
@import "./app/styles/classes";
@import "./app/styles/base";
@import "./app/styles/animation";
@import "./app/styles/animations_mobile";
.hidden {
  display: none;
}
body{
  font-family: Montserrat-Regular ,sans-serif;
  padding: 0 ;
  margin: 0 auto 0;
  position: relative;
}
header{
  width: 100%;
}
img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}
footer{
  width: 100%;
  position: relative;
}
@media screen and (min-width: 1022px) {
  footer{
    bottom: 0;
    height: 200px !important;
  }
}
