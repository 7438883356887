// animation rotating img
.rotatingText {
  display: inline-block;
  .spin{
    animation-name: rotate-circle;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    cursor: pointer;
  }
  .spinner_center{
    position: absolute;
  }

}
.rotatingText:hover > .spin {
  animation-play-state: paused;
}

@keyframes rotate-circle {
  to {
    transform: rotate(1turn);
  }
}
// animation growing circle
.circle {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #fab514;
  border: 2px solid #fab514;
  border-radius: 50%;
  top: 7rem;
  left: 2rem;
}
.circle2{
  position: relative;
  width: 175px;
  height: 175px;
  background-color: #fab514;
  border: 2px solid #fab514;
  border-radius: 50%;
  top: 14rem;
}


//  verticalMovement from right to center to bottom move
.myDownMove{
  display: block !important;
  position: relative;
  -webkit-animation: myVerticalMove 3s forwards ease;
}
@-webkit-keyframes myVerticalMove {
  20%{
    opacity: 0.6;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(500px);
    -moz-transform: translateY(500px);
    -ms-transform: translateY(500px);
    -o-transform: translateY(500px);
    transform: translateY(500px) scale(3) ;
  }
}
// left to center into frame
.leftToFrame{
  display: block !important;
  position: relative;
  left: -150px;
  -webkit-animation: leftToFrame 2s forwards ease-out;
}
.leftToInsideFrameMove {
  display: block !important;
  position: relative;
}
@-webkit-keyframes leftToFrame {
  to {
    -webkit-transform: translateY(250px);
    -moz-transform: translateY(250px);
    -ms-transform: translateY(250px);
    -o-transform: translateY(250px);
    transform:  translateX(250px) scale(1.5)  ;
  }
}
//right to frame
.yellow_circle_moving_right_web{
  display: none;
  position: relative;
  left: 110%;
  top: -20rem;
  width: 225px;
  height: 225px;
  border-radius: 50%;
  background-color: #FAB514;
  overflow: hidden;
}
.yellow_circle_moving_right_animations_web{
  display: inline-block;
  animation: moveRight_web 2s ease-out forwards;
}
@keyframes moveRight_web {

  to {
    left: 80%;
    scale: 1.5;
  }
}
.fadeIn{
  animation: fadeIn 1s ease-in 0s forwards;
  display: flex;
}
.fadeOut{
  animation: reveal 1s ease-in 0s forwards;
  display: flex;
}
@keyframes fadeIn {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0 );
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
