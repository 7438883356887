// this is for some of the most used cases css
.pointer{
  cursor: pointer;
}
.flexBox{
  display: flex;
  justify-content: center;
}
.center{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.uppercase{
  text-transform: uppercase;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-items: baseline;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.grid-item {
  padding: 2px;
  text-align: center;
  margin-bottom: 1rem;
  height: 150px;

}
/* Every odd number after the third item */
.grid-item:nth-child(3n +3){
  grid-column: span 2;
}
.dot {
  color: #F9B514;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: Montserrat-Bold, sans-serif;
}
.whitedot {
  color: white !important;
  margin-right: 1rem;
}
.inline_block{
  display: inline-block;
  height: 100%;
}
.floatingLogo{
  position: absolute;
  z-index: 15;
  top: 540px;
  right: 7%;
}
.mt-3{
  margin-top: 3rem;
}
.opacity{
  opacity: 0.75;
}
@media screen and (min-width: 1022px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    box-sizing: border-box;
    padding: 2rem;
  }
  .grid-item:nth-child(3n+3) {
    grid-column: span 1 !important;
  }
}
